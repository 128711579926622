import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Bio from '../components/Bio'

const about = (props) => {

  const siteTitle = 'About Us'
  const siteDescription = 'Full Meta Marketing is a web development and digital Marketing agency providing SEO, Paid Search and web development services to small to medium size businesses.'

  return (
    <Layout location={props.location} title={siteTitle}>
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      meta={[{ name: 'description', content: siteDescription }]}
      title={siteTitle}
    />
    <Bio />
    </Layout>
  )
}

export default about
